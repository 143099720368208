/* Background styles */
/* https://ui.glass/generator/ */
body {
  background-image: 
    radial-gradient(at 47% 33%, hsl(211.81, 80%, 41%) 0, transparent 59%), 
    radial-gradient(at 82% 65%, hsl(218.00, 39%, 11%) 0, transparent 55%);
  background-repeat: no-repeat;
  height: 100vh;
  
} 

/* Glassmorphism card effect */
.page-row-card {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 60, 72, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

/* Generated by https://generator.ui.glass/ */

.mk-brand-name {
  font-size: 1.5em;
  font-family: 'Mona Sans', sans-serif;
  font-weight: 200;
}

.mk-brand-name-bolder {
  font-weight: 600;
}

.hero-content {
  width: 100%;
  text-align: center;
}

.hero-title {
  font-family: 'Mona Sans';
  font-weight: 900;
}

.hero-subtitle {
  border-radius: 48px;
  border: 2px solid #b2a5f9;
  background-color: transparent;
  color: #b2a5f9;
  padding: 4px 20px;
  text-decoration: none;
  display: inline-block;
  font-family: 'Mona Sans', sans-serif;
  font-size: 0.8rem;
}

.mk-heading-secondary {
  font-family: 'Lexend', sans-serif;
}

.mk-nav-links {
  font-family: 'Mona Sans', sans-serif;
  font-weight: 400;
}

.mk-gradient-heading-1 {
  font-family: 'Mona Sans', sans-serif;
  font-weight: bolder;
  // background: linear-gradient(135deg, #3d2aa8 0%, #930067 100%);
  // background: -webkit-linear-gradient(-45deg, #3d2aa8 0%, #930067 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

pre.highlight {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 14px;
}

/* _sass/_post.css */
pre.highlight>button {
  opacity: 0;
}

pre.highlight:hover>button {
  opacity: 1;
}

pre.highlight>button:active,
pre.highlight>button:focus {
  opacity: 1;
}

.mk-btn-position {
  position: relative;
  float: right;
  display: block;
}

.highlight { margin-top: 10px; }

.carousel-caption {
  color:azure !important;
}

.footer-brand {
  font-family: 'Lexend', sans-serif;
  font-size: 8em;
  font-weight: 600;
  text-transform: uppercase;
}