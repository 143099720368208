.highlight-background {
    background: #3d2aa8;
    background: -moz-linear-gradient(-45deg, #3d2aa8 0%, #930067 100%);
    background: -webkit-linear-gradient(-45deg, #3d2aa8 0%,#930067 100%);
    background: linear-gradient(135deg, #3d2aa8 0%,#930067 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d2aa8', endColorstr='#930067',GradientType=1 );
}

.highlight-background-sub {
    background: #fceabb;
    background: -moz-linear-gradient(-45deg, #fceabb 0%, #fccd4d 50%, #f8b500 51%, #fbdf93 100%);
    background: -webkit-linear-gradient(-45deg, #fceabb 0%,#fccd4d 50%,#f8b500 51%,#fbdf93 100%);
    background: linear-gradient(135deg, #fceabb 0%,#fccd4d 50%,#f8b500 51%,#fbdf93 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fceabb', endColorstr='#fbdf93',GradientType=1 );
}

.mk-background-subtle-1 {
  background-color: #FAACA8;
  background-image: linear-gradient(19deg, #fee7e6 0%, #eceaf5 100%);
}

.mk-background-subtle-2 {
  background-color: #FAACA8;
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

.mk-background-blog {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(37, 145, 251, 0.98) 0.1%, rgba(0, 7, 128, 1) 99.8%);
  color: #fafafa;
}

.mk-graph-paper-background {
  color: #fafafa;
  background-color: #DFDBE5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.hero-background {
  background-color: #85004d;
  background-image: linear-gradient(225deg, #85004d 0%, #8853bb 50%, #064a79 100%);
}