@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
@import url("https://fonts.googleapis.com/css?family=Macondo+Swash+Caps");
@import url("https://fonts.googleapis.com/css?family=K2D");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Mona+Sans:ital,wght@0,200..900;1,200..900&display=swap');

$font-family-sans-serif: "Inter", sans-serif;
$font-family-monospace: "Oxygen Mono", SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono";
